import React, { useState } from 'react'

const FaqCard = ({ title, text, _isOpen }) => {
    const [isOpen, setIsOpen] = useState(_isOpen)
    return (
        <div
            role="button"
            tabIndex={0}
            onClick={() => setIsOpen(!isOpen)}
            onKeyDown={() => setIsOpen(!isOpen)}
            className={`ow-card ow-card--shadow faq-card ${isOpen && 'faq-card--active'}`}
        >
            <div className="faq-card-header">
                <h3 className="faq-card__title"><strong>{title}</strong></h3>
                <span className="faq-card-plus"> {isOpen ? '-' : '+'} </span>
            </div>
            <p className="faq-card__text">{text}</p>
        </div>
    )
}

export default FaqCard
