import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../components/layout'
import Nav from '../components/nav'
import SEO from '../components/seo'
import FaqCard from '../components/content/faqCard'
import '../styles/index.scss'

import OptBtn from '../components/button'

// Content components
import Integrations from '../components/content/integrations'
import Stories from '../components/stories'

// channels
import bannerImg from '../images/home/redesign01/banner-home.png'
import u01Img from '../images/home/redesign01/u01.png'
import u02Img from '../images/home/redesign01/u02.png'
import u03Img from '../images/home/redesign01/u03.png'
import u04Img from '../images/home/redesign01/u04.png'
import u05Img from '../images/home/redesign01/u05.png'
import u06Img from '../images/home/redesign01/u06.png'
import mobile from '../images/home/redesign01/mobile.png'

// apps
import androidImg from '../images/home/apps/android.png'
import iosImg from '../images/home/apps/ios.png'

// customers
import customer_aldeas from '../images/home/clients/customer_aldeas.png'
import customer_baristi from '../images/home/clients/customer_baristi.png'
import customer_ccti from '../images/home/clients/customer_ccti.png'
import customer_conoc from '../images/home/clients/customer_conoc.png'
import customer_crucijuegos from '../images/home/clients/customer_crucijuegos.png'
import customer_descuentos from '../images/home/clients/customer_descuentos.png'
import customer_ginza from '../images/home/clients/customer_ginza.png'
import customer_ioimporto from '../images/home/clients/customer_ioimporto.png'
import customer_mcc from '../images/home/clients/customer_mcc.png'
import customer_med from '../images/home/clients/customer_med.png'
import customer_reba from '../images/home/clients/customer_reba.png'
import customer_san_lorenzo from '../images/home/clients/customer_san_lorenzo.png'
import customer_whyline from '../images/home/clients/customer_whyline.png'
import customer_xe_seguridad from '../images/home/clients/customer_xe_seguridad.png'

import { ErrorBoundaryWithLogger } from '../frontend-lib/Log'

function Index ({ lang }) {
    const text = {
        es: {
            cs_jobTitle_1: 'CEO en Reba',
            cs_title_1:
                'La mejor plataforma para atención automatizada y centralizada en WhatsApp',
            cs_description_1:
                'Encontramos en Optiwe el partner ideal para mejorar significativamente la experiencia de servicio a nuestros clientes. En solo unos pocos meses pudimos automatizar el 40% de los mensajes/preguntas de nuestros clientes y ofrecer atención personalizada con AI 24x7.',
            cs_jobTitle_2: 'Asesora de Campaña',
            cs_title_2: 'El mejor CRM para WhatsApp',
            cs_description_2:
                'En Optiwe encontramos el partner ideal para escalar nuestro servicio al donante a través de nuestro sitio web y WhatsApp. También, gracias a sus herramientas de outbound, podemos comunicarnos con nuevos potenciales donantes en tiempo real y brindarles la mejor experiencia posible. Estamos muy felices de contar con la tecnología de Optiwe para potenciar a nuestros equipos de atención al donante.',
            page_title: 'Optiwe - Atención al Cliente Multiagente y CRM WhatsApp',
            page_description:
                'Optiwe es el mejor CRM WhatsApp multiagente y multicanal para atender clientes en WhatsApp, Instagram, Facebook y tu sitio web.',
            page_lang: 'es',
            h1_strong: 'CRM para WhatsApp',
            h1_normal:
                'que te ayuda a vender más y atender mejor a tus clientes 24x7 con ChatGPT',
            h1_description_1:
                'Responde conversaciones de Ventas y Atención al Cliente automáticamente con ChatGPT y de manera centralizada en WhatsApp, Facebook, Instagram y tu sitio web.',
            h1_description_2:
                'Optiwe es el CRM para WhatsApp Multiagente y Multicanal que te ayuda a aumentar la productividad de tu equipo de Atención al Cliente y convertir las conversaciones en ventas con ChatGPT.',
            demo: 'Hablar en WhatsApp',
            test: 'Probar gratis',
            customers:
                'Sumate a las empresas que ya usan nuestro CRM WhatsApp Business potenciado por ChatGPT para hacer crecer sus negocios y mejorar la comunicación con sus clientes',
            h2_benefits: 'Beneficios de utilizar Optiwe en tu negocio',
            h2_benefits_description:
                'Optiwe utiliza las últimas tecnologías en Inteligencia Artificial para ayudarte a vender por WhatsApp y optimizar tu negocio en tan solo 30 días',
            h2_benefits_1:
                'de aumento en la productividad de tu equipo de ventas y atención al usar nuestras herramientas inteligentes para chatear con clientes.',
            h2_benefits_2:
                'de automatización de las conversaciones de ventas y atención al cliente con nuestros chatbots y flujos inteligentes en tan solo 30 días.',
            h2_benefits_3:
                'de aumento en tus ventas en canales digitales al conectar tu sitio web de manera directa con los canales WhatsApp, Instagram y Facebook.',
            h2_productos: '¿Qué productos ofrece Optiwe?',
            h2_prod_1_title: 'Integración con ChatGPT y canales digitales',
            h2_prod_1_description:
                'Vende y comunica de manera centralizada con ChatGPT en una única plataforma: desde WhatsApp, Facebook, Instagram hasta tu sitio web y por SMS. Nuestro CRM utiliza WhatsApp Business API oficial.',
            h2_prod_1_alt: 'WhatsApp, Instagram y Facebook integrados a Optiwe',
            h2_prod_1_link: 'Más información sobre canales',
            h2_prod_2_title: 'Atención multicanal',
            h2_prod_2_description:
                'Gestiona todas las conversaciones y contactos en una bandeja de entrada centralizado. Dale superpoderes a tus vendedores con respuestas sugeridas. Utiliza nuestro CRM compatible con WhatsApp.',
            h2_prod_2_alt: 'Bandeja de entrada multicanal de Optiwe',
            h2_prod_2_link: 'Módulo Agente de Atención',
            h2_prod_3_title: 'Chatbot multicanal',
            h2_prod_3_description:
                'Automatiza tus ventas y servicio al cliente en WhatsApp utilizando chatbots inteligentes. Con el diseñador de bots de Optiwe puedes construir flujos para que tus clientes obtengan las respuestas que buscan y compren automáticamente tus productos.',
            h2_prod_3_alt:
                'Chatbots en WhatsApp, Instagram y Facebook diseñados con Optiwe',
            h2_prod_3_link: 'Módulo Chatbots',
            h2_prod_4_title: 'WhatsApp Marketing',
            h2_prod_4_description:
                'Importa los datos de tus clientes, administra listas de contactos y realiza potentes campañas de marketing masivas con nuestro CRM integrado WhatsApp. Obtén una tasa de lectura y respuesta superior al email marketing con publicidad personalizada en WhatsApp.',
            h2_prod_4_alt: 'Creación de campañas de marketing en WhatsApp con Optiwe',
            h2_prod_4_link: 'Módulo Marketing Digital',
            h2_prod_5_title: 'Reportes y estadísticas',
            h2_prod_5_description:
                'Obtén un reporte detallado sobre la efectividad de cada canal, la performance de tus representantes comerciales y la efectividad de tus Chatbots.',
            h2_prod_5_alt:
                'Chatbots en WhatsApp, Instagram y Facebook diseñados con Optiwe',
            h2_prod_5_link: 'Módulo Reportes',
            h2_prod_6_title: 'Integraciones con otras herramientas',
            h2_prod_6_description:
                'Integra Optiwe con todas las herramientas que tu empresa ya utiliza. Sincroniza contactos y tickets con HubSpot, SalesForce, Zendesk y otros CRM.',
            h2_prod_6_alt: 'Integraciones con API REST a otras herramientas',
            register: 'Regístrate, es gratis!',
            h2_apps: '¡Descarga la aplicación en tu celular!',
            h2_apps_android_alt: 'Link de descarga a la app de Optiwe para Android',
            h2_apps_ios_alt: 'Link de descarga a la app de Optuwe para iOS',
            h2_cs:
                'Las empresas que más venden y mejor atienden a sus clientes ya utilizan Optiwe',
            h2_canales: 'Canales de comunicación',
            h2_canales_h3:
                'Comunícate en los canales donde tus clientes te están buscando.',
            h2_canales_description:
                'Utiliza nuestra plataforma de ventas en WhatsApp, Instagram, Facebook, tu sitio web y muchos canales más. Te ayudamos a crear chatbots y reglas de derivación inteligentes a agentes de ventas, para ofrecer la mejor experiencia omnicanal a todos tus clientes! Nuestro CRM utiliza WhatsApp Business API oficial.',
            h2_canales_whatsapp_platform: 'WhatsApp Business Platform',
            h2_canales_whatsapp_business_api: 'WhatsApp Business API oficial',
            h2_canales_chatgpt: 'ChatGPT',
            h2_integrations: '¿Qué integraciones ofrece Optiwe?',
            talk: '¡Hablemos!',
            h2_faq: 'Preguntas Frecuentes',
            faq_1: '¿Qué es un CRM para WhatsApp?',
            faq_1_description:
                'Optiwe WhatsApp CRM es un sistema de gestión de relaciones con los clientes que utiliza la plataforma de mensajería WhatsApp para comunicarse con los clientes y proporcionar un servicio más personalizado. Con Optiwe WhatsApp CRM, las empresas pueden automatizar la respuesta a preguntas frecuentes, clasificar y priorizar mensajes y proporcionar un seguimiento más eficiente de los clientes. También puede ayudar a las empresas a recopilar información sobre los clientes y utilizarla para mejorar su experiencia y fidelizarlos. En resumen, un WhatsApp CRM es una herramienta útil para las empresas que buscan mejorar su servicio al cliente y aumentar la satisfacción del cliente a través de una plataforma de mensajería popular y fácil de usar.',
            faq_2: '¿Puedo vender por WhatsApp?',
            faq_2_description:
                'Con la plataforma de Optiwe puedes automatizar tus ventas a través de WhatsApp. Muchas empresas han adoptado la plataforma de mensajería para llegar a sus clientes y ofrecer sus productos o servicios. Algunas empresas utilizan chatbots o asistentes automatizados para responder preguntas y ayudar a los clientes a realizar sus compras a través de la plataforma. Otros usan la función de mensajería directa para comunicarse directamente con los clientes y procesar sus órdenes.',
            faq_3: '¿Qué es WhatsApp Business API?',
            faq_3_description:
                'Optiwe utiliza WhatsApp Business API oficial, una plataforma de programación de aplicaciones (API) que permite a las empresas automatizar, optimizar y personalizar sus comunicaciones con los clientes a través de la aplicación de mensajería WhatsApp. Con la WhatsApp Business API, las empresas pueden enviar mensajes automatizados, clasificar y priorizar mensajes entrantes, y proporcionar una experiencia de atención al cliente más eficiente. La API también permite a las empresas recopilar información sobre los clientes, como su ubicación, preferencias y historial de compras, para utilizarla para personalizar sus comunicaciones y ofrecer una experiencia de atención al cliente más relevante y satisfactoria.',
            faq_4: '¿Qué es WhatsApp Multiagente?',
            faq_4_description:
                'Optiwe WhatsApp Multiagente es una función que permite a las empresas tener múltiples agentes o representantes que respondan a los mensajes de los clientes a través de la plataforma de mensajería WhatsApp. Con WhatsApp Multiagente, las empresas pueden asegurarse de que los mensajes de los clientes se respondan de manera eficiente y oportuna, incluso durante períodos de alta demanda.'
        },
        en: {
            cs_jobTitle_1: 'CEO at Reba',
            cs_title_1:
                'The best platform for automated and centralized attention on WhatsApp',
            cs_description_1:
                "We found in Optiwe the ideal partner to significantly improve the service experience for our customers. In just a few months, we were able to automate 40% of our customers' messages/questions and offer personalized AI 24x7 attention.",
            cs_jobTitle_2: 'Campaign Advisor',
            cs_title_2: 'The best CRM for WhatsApp',
            cs_description_2:
                "At Optiwe, we found the ideal partner to scale our donor service through our website and WhatsApp. Also, thanks to its outbound tools, we can communicate with new potential donors in real-time and provide them with the best possible experience. We are very happy to have Optiwe's technology to empower our donor service teams.",
            page_title: 'Optiwe - Multi-agent Customer Service and WhatsApp CRM',
            page_description:
                'Optiwe is the best multi-agent and multi-channel WhatsApp CRM to serve customers on WhatsApp, Instagram, Facebook, and your website.',
            page_lang: 'en',
            h1_strong: 'CRM for WhatsApp',
            h1_normal:
                'that helps you increase your online sales and serve your customers 24x7 with ChatGPT',
            h1_description_1:
                'Respond to Sales and Customer Service conversations automatically with ChatGPT and centralize all the interactions on WhatsApp, Facebook, Instagram, and your website in a powerful CRM.',
            h1_description_2:
                "Optiwe is the Multi-agent and Multi-channel WhatsApp CRM that helps you increase your Customer Service team's productivity and convert conversations into sales with ChatGPT.",
            demo: 'Talk on WhatsApp',
            test: 'Try for free',
            customers:
                'Join the companies that already use our WhatsApp Business CRM powered by ChatGPT to grow their businesses and improve communication with their customers',
            h2_benefits: 'Benefits of using Optiwe in your business',
            h2_benefits_description:
                'Optiwe uses the latest Artificial Intelligence technologies to help you sell on WhatsApp and optimize your business in just 30 days',
            h2_benefits_1:
                'increase in sales and customer service team productivity by using our intelligent tools to chat with customers.',
            h2_benefits_2:
                'of automation of sales and customer service conversations with our intelligent chatbots and flows in just 30 days.',
            h2_benefits_3:
                'increase in your digital channel sales by directly connecting your website with WhatsApp, Instagram, and Facebook channels.',
            h2_productos: 'What products does Optiwe offer?',
            h2_prod_1_title: 'Integration with ChatGPT and digital channels',
            h2_prod_1_description:
                'Sell and communicate with ChatGPT on a single platform: from WhatsApp, Facebook, Instagram to your website and SMS. Our CRM uses the official WhatsApp Business API.',
            h2_prod_1_alt: 'WhatsApp, Instagram, and Facebook integrated into Optiwe',
            h2_prod_1_link: 'More information about channels',
            h2_prod_2_title: 'Multichannel customer service',
            h2_prod_2_description:
                'Manage all conversations and contacts in a centralized inbox. Give superpowers to your salespeople with suggested responses. Use our CRM compatible with WhatsApp.',
            h2_prod_2_alt: "Optiwe's multichannel inbox",
            h2_prod_2_link: 'Attention Agent Module',
            h2_prod_3_title: 'Multichannel Chatbot',
            h2_prod_3_description:
                "Automate your sales and customer service on WhatsApp using intelligent chatbots. With Optiwe's bot designer, you can build flows so your customers can get the answers they need and automatically purchase your products.",
            h2_prod_3_alt:
                'WhatsApp, Instagram, and Facebook chatbots designed with Optiwe',
            h2_prod_3_link: 'Chatbot Module',
            h2_prod_4_title: 'WhatsApp Marketing',
            h2_prod_4_description:
                'Import your customer data, manage contact lists, and carry out powerful mass marketing campaigns with our integrated WhatsApp CRM. Get a higher read and response rate than email marketing with personalized advertising on WhatsApp.',
            h2_prod_4_alt: 'Creation of WhatsApp marketing campaigns with Optiwe',
            h2_prod_4_link: 'Digital Marketing Module',
            h2_prod_5_title: 'Reports and statistics',
            h2_prod_5_description:
                'Get a detailed report on the effectiveness of each channel, the performance of your sales representatives, and the effectiveness of your Chatbots.',
            h2_prod_5_alt:
                'WhatsApp, Instagram, and Facebook chatbots designed with Optiwe',
            h2_prod_5_link: 'Reports Module',
            h2_prod_6_title: 'Integrations with other tools',
            h2_prod_6_description:
                'Integrate Optiwe with all the tools your company already uses. Synchronize contacts and tickets with HubSpot, SalesForce, Zendesk, and other CRMs.',
            h2_prod_6_alt: 'Integrations with REST API to other tools',
            register: "Register, it's free!",
            h2_apps: 'Download the APP in your phone!',
            h2_apps_android_alt: 'Link to download the Optiwe Android app',
            h2_apps_ios_alt: 'Link to download the Optiwe iOS app',
            h2_cs:
                'The companies that sell the most and best serve their customers already use Optiwe',
            h2_canales: 'Communication Channels',
            h2_canales_h3:
                'Communicate on the channels where your customers are looking for you.',
            h2_canales_description:
                'Use our sales platform on WhatsApp, Instagram, Facebook, your website, and many more channels. We help you create smart chatbots and derivation rules for sales agents, to offer the best omnichannel experience to all your customers! Our CRM uses official WhatsApp Business API.',
            h2_canales_whatsapp_platform: 'WhatsApp Business Platform',
            h2_canales_whatsapp_business_api: 'Official WhatsApp Business API',
            h2_canales_chatgpt: 'ChatGPT',
            h2_integrations: 'What integrations does Optiwe offer?',
            talk: "Let's talk!",
            h2_faq: 'Frequently Asked Questions',
            faq_1: 'What is a CRM for WhatsApp?',
            faq_1_description:
                'Optiwe WhatsApp CRM is a customer relationship management system that uses the WhatsApp messaging platform to communicate with customers and provide more personalized service. With Optiwe WhatsApp CRM, businesses can automate responses to frequently asked questions, classify and prioritize messages, and provide more efficient follow-up to customers. It can also help businesses collect information about customers and use it to improve their experience and build customer loyalty. In summary, a WhatsApp CRM is a useful tool for businesses looking to improve their customer service and increase customer satisfaction through a popular and easy-to-use messaging platform.',
            faq_2: 'Can I sell on WhatsApp?',
            faq_2_description:
                "With Optiwe's platform, you can automate your sales through WhatsApp. Many businesses have adopted the messaging platform to reach their customers and offer their products or services. Some companies use chatbots or automated assistants to answer questions and help customers make their purchases through the platform. Others use the direct messaging feature to communicate directly with customers and process their orders.",
            faq_3: 'What is WhatsApp Business API?',
            faq_3_description:
                'Optiwe uses the official WhatsApp Business API, an application programming interface (API) platform that allows businesses to automate, optimize, and personalize their communications with customers through the WhatsApp messaging app. With the WhatsApp Business API, companies can send automated messages, classify and prioritize incoming messages, and provide a more efficient customer service experience. The API also allows companies to collect information about customers, such as their location, preferences, and purchase history, to use for personalizing their communications and offering a more relevant and satisfying customer service experience.',
            faq_4: 'What is WhatsApp Multi-agent?',
            faq_4_description:
                "Optiwe's WhatsApp Multi-agent is a feature that allows businesses to have multiple agents or representatives respond to customer messages through the WhatsApp messaging platform. With WhatsApp Multi-agent, companies can ensure that customer messages are efficiently and timely responded to, even during periods of high demand."
        }
    }

    /*
     ***************
     * SLIDER SETTINGS *
     ***************
     */
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    autoplay: true,
                    speed: 2000,
                    autoplaySpeed: 2000,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    autoplay: true,
                    speed: 2000,
                    autoplaySpeed: 2000,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            }
        ]
    }

    /*
     *********************
     * CUSTOMERS STORIES *
     *********************
     */
    const clientStories = [
        {
            id: '1',
            name: 'Stefano Angeli',
            jobTitle: text[lang].cs_jobTitle_1,
            title: text[lang].cs_title_1,
            description: text[lang].cs_description_1,
            img: customer_reba,
            stars: 5
        },
        {
            id: '2',
            name: 'Noelia Nuñez',
            jobTitle: text[lang].cs_jobTitle_2,
            title: text[lang].cs_title_2,
            description: text[lang].cs_description_2,
            img: customer_aldeas,
            stars: 5
        }
    ]

    function renderSignUpButton (buttonText) {
        if (lang === 'en') {
            return (
                <OptBtn isLink rounded small href="/sign-up/">
                    {text[lang][buttonText]}
                </OptBtn>
            )
        }

        if (lang === 'es') {
            return (
                <OptBtn isLink rounded small href="/es/crear-cuenta/">
                    {text[lang][buttonText]}
                </OptBtn>
            )
        }

        throw new Error('Language not supported')
    }

    function renderWhatsAppButton (buttonText) {
        if (lang === 'en') {
            return (
                <OptBtn isLink rounded small href="https://wa.me/+14157040340">
                    {text[lang][buttonText]}
                </OptBtn>
            )
        }

        if (lang === 'es') {
            return (
                <OptBtn isLink rounded small href="https://wa.me/+5491152351034">
                    {text[lang][buttonText]}
                </OptBtn>
            )
        }

        throw new Error('Language not supported')
    }

    return (
        <ErrorBoundaryWithLogger serverUrl={process.env.GATSBY_SERVER_URL} logName="website">
            <Layout lang={lang}>
                <SEO
                    title={text[lang].page_title}
                    description={text[lang].page_description}
                    lang={text[lang].page_lang}
                    locales={[
                        { hreflang: 'en', href: 'https://optiwe.com/' },
                        { hreflang: 'es', href: 'https://optiwe.com/es/' },
                        { hreflang: 'x-default', href: 'https://optiwe.com/' }
                    ]}
                />

                <main className="container-fluid ow-banner">
                    {/*
                    ^*********
                    * HEADER *
                    **********
                    */}
                    <Nav
                        lang={lang}
                        showButtons
                    />

                    {/*
                     ***************
                     * MAIN BANNER *
                     ***************
                     * TODO: If the banner is used in another view, create a <Banner /> component
                     */}
                    <div className="container ow-banner__content">
                        <div className="row">
                            <div className="col-xl-6 col-xxl-6 ow-banner__home-content">
                                <h1 className="ow-banner__title">
                                    <strong>{text[lang].h1_strong}</strong>
                                    {` ${text[lang].h1_normal}`}
                                </h1>
                                <p className="ow-banner__text">{text[lang].h1_description_1}</p>
                                <p className="ow-banner__text">{text[lang].h1_description_2}</p>
                                <div className="row">
                                    <div className="col-xl-4 mb-3 mb-md-3">
                                        {renderWhatsAppButton('demo')}
                                    </div>
                                    <div className="col-xl-4">{renderSignUpButton('test')}</div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xxl-7 ow-banner__home-image">
                                <img src={bannerImg} alt="optiwe" className="ow-banner__img" />
                            </div>
                        </div>
                    </div>
                </main>

                <section className="container-fluid ow-section ow-section--features" id="features">
                    <div className="container success-stories">
                        <div className="row justify-content-center mb-5">
                            <div className="col-md-10">
                                <h2 className="ow-section__title">
                                    <span className="bold">{text[lang].customers}</span>
                                </h2>
                            </div>
                            <div className="row">
                                <div className="col-md-10"></div>
                            </div>
                        </div>
                        <div className="mb-5">
                            <Slider {...sliderSettings}>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_aldeas}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_baristi}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_reba}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_ccti}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_conoc}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_crucijuegos}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_descuentos}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_ginza}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_ioimporto}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_mcc}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_med}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_san_lorenzo}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_whyline}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img
                                        src={customer_xe_seguridad}
                                        alt="optiwe"
                                        className="ow-features__img"
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>

                {/*
                ****************
                * PORCENTAJES  *
                ****************
                */}
                <section className="benefits-shapes">
                    <svg
                        id="benefits_top_shape"
                        data-name="benefits_top_shape"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        viewBox="0 0 1920 143.11"
                    >
                        <path
                            className="benefits_top_shape"
                            d="M1920,143.11V38.75C1692.68,104.88,1412.86,127.9,1184.41,89,1083.28,71.8,983,44.3,880.43,45.1,705.88,46.4,536.94,129.6,363.09,114c-77.32-7-151.94-33.2-229.26-39.5C89,70.85,41.09,75.79,0,92.94l.22,50.16Z"
                        />
                        <path
                            className="benefits_top_shape"
                            d="M384,72.49c5.25-.88,10.65-1.89,15.23-4.62s8.23-7.75,7.92-13-4.74-9.86-9.87-11.6-10.8-1-15.93.58c-4,1.19-8.2,3-12.06,1.58-4.14-1.57-6.17-6-8.85-9.53C353,25.85,337.1,22.4,327.17,30c-6,4.54-9.81,15.35-7.06,22.55a25.66,25.66,0,0,0,5.42,8c6.64,6.93,17.09,14.23,27,15.22S374,74.25,384,72.49Z"
                        />
                        <path
                            className="benefits_top_shape"
                            d="M443.55,31.61c3.18-3.33,5.56-8.65,2.74-12.32-2.34-3-7.15-3-10.31-.93-1.72,1.09-4.24,3.37-4.92,5.41-1.2,3.23-1.83,9.65,1.81,11.43C436.71,37.19,440.95,34.38,443.55,31.61Z"
                        />
                    </svg>
                    <div className="container-fluid ow-section--cards" id="benefits">
                        <div className="container">
                            <h2 className="ow-section--cards__title">
                                <span className="bold">{text[lang].h2_benefits}</span>
                            </h2>
                            <p className="ow-section--cards__desc">
                                {text[lang].h2_benefits_description}
                            </p>
                            <div className="row mt-md-5 ow-section--row ow-section--benefits">
                                <div className="col-md-4 ow-section--col mt-3 mt-md-0">
                                    <div className="ow-benefit">
                                        <div className="ow-benefit-number-container">
                                            <svg
                                                className=""
                                                width="200"
                                                height="151"
                                                viewBox="0 0 289 219"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.09996 150.4C2.39996 152.1 2.69996 153.7 2.99996 155.3C9.49996 187.5 32.5 212.8 65.7 217.3C109.8 223.2 134.5 192.7 172.7 179.8C197.6 171.4 224.9 171.6 248.9 160.3C270.9 150 287 127.3 288 103C289 78.7 274.2 54.1 251.7 45C233.5 37.6 212.5 40.1 193.9 33.6C177.9 28 165 16.2 149.8 8.80004C106.8 -12.1 48.5 9.70004 22.1 47.1C2.49996 74.7 -3.40004 116.9 2.09996 150.4Z"
                                                    fill="#35209E"
                                                />
                                            </svg>
                                            <p className="ow-benefit__number">+25%</p>
                                        </div>
                                        <p className="ow-benefit__text">
                                            {text[lang].h2_benefits_1}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 ow-section--col mt-3 mt-md-0">
                                    <div className="ow-benefit">
                                        <div className="ow-benefit-number-container">
                                            <svg
                                                width="200"
                                                height="151"
                                                viewBox="0 0 309 205"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M83.7 203C24.1 195.6 -4.70001 141.9 1.59999 85.1C3.59999 67.2 9.2 48.1 20.2 33.6C27.4 24.2 37.7 16.8 49.5 15.4C64.9 13.6 79.3 22 94.1 26.8C131.2 38.9 162.4 23.2 196.4 10.3C218.1 2.09997 242.4 -3.80003 264.3 3.69997C295 14.2 312.5 49.8 308.4 82C304.3 114.2 282.1 141.9 255.1 159.8C228 177.7 196.3 186.9 164.8 194.5C138.2 201 110.8 206.4 83.7 203Z"
                                                    fill="#35209E"
                                                />
                                            </svg>
                                            <p className="ow-benefit__number">+30%</p>
                                        </div>
                                        <p className="ow-benefit__text">
                                            {text[lang].h2_benefits_2}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 ow-section--col mt-3 mt-md-0">
                                    <div className="ow-benefit">
                                        <div className="ow-benefit-number-container">
                                            <svg
                                                width="200"
                                                height="151"
                                                viewBox="0 0 289 219"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.09996 150.4C2.39996 152.1 2.69996 153.7 2.99996 155.3C9.49996 187.5 32.5 212.8 65.7 217.3C109.8 223.2 134.5 192.7 172.7 179.8C197.6 171.4 224.9 171.6 248.9 160.3C270.9 150 287 127.3 288 103C289 78.7 274.2 54.1 251.7 45C233.5 37.6 212.5 40.1 193.9 33.6C177.9 28 165 16.2 149.8 8.80004C106.8 -12.1 48.5 9.70004 22.1 47.1C2.49996 74.7 -3.40004 116.9 2.09996 150.4Z"
                                                    fill="#35209E"
                                                />
                                            </svg>
                                            <p className="ow-benefit__number">15%</p>
                                        </div>
                                        <p className="ow-benefit__text">
                                            {text[lang].h2_benefits_3}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg
                        id="benefits_bottom_shape"
                        className="benefits_bottom_shape"
                        preserveAspectRatio="xMidYMid slice"
                        viewBox="0 0 1920 143.11"
                        data-name="benefits_bottom_shape"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            className="benefits_bottom_shape"
                            d="M0,0V25.07C100.42,73.33,222.92,85.71,339.3,81.39c156.8-5.8,310.1-35.9,467.5-35.3,150.2.5,300.5,20.5,450.6,13.9,134.5-5.9,267.1-42.9,402.7-27.6,84,9.52,172.75,28.69,259.9,33.2V0Z"
                        />
                    </svg>
                </section>

                {/*
                ************
                * FEATURES *
                ************
                */}
                <section className="container-fluid ow-section ow-section--features" id="features">
                    <div className="container features">
                        <div className="row">
                            <div className="col-md-12 features__content">
                                <h2 className="ow-section__title">
                                    <span className="bold">{text[lang].h2_productos}</span>
                                </h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="ow-features__title">
                                    {text[lang].h2_prod_1_title}
                                </h3>
                                <p className="ow-features__text">
                                    {text[lang].h2_prod_1_description}
                                </p>
                                <img
                                    className="ow-features__img"
                                    src={u03Img}
                                    alt={text[lang].h2_prod_1_alt}
                                />
                                <div className="ow-features__btn">
                                    <OptBtn
                                        isLink
                                        href="https://optiwe.com/es/canales/whatsapp/"
                                        rounded
                                        small
                                        className="ws-button"
                                    >
                                        {text[lang].h2_prod_1_link}
                                    </OptBtn>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="ow-features__title">
                                    {text[lang].h2_prod_2_title}
                                </h3>
                                <p className="ow-features__text">
                                    {text[lang].h2_prod_2_description}
                                </p>
                                <img
                                    className="ow-features__img"
                                    src={u04Img}
                                    alt={text[lang].h2_prod_2_alt}
                                />
                                <div className="ow-features__btn">
                                    <OptBtn
                                        isLink
                                        href="https://optiwe.com/es/productos/whatsapp-multiagente/"
                                        rounded
                                        small
                                        className="ws-button"
                                    >
                                        {text[lang].h2_prod_2_link}
                                    </OptBtn>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="ow-features__title">
                                    {text[lang].h2_prod_3_title}
                                </h3>
                                <p className="ow-features__text">
                                    {text[lang].h2_prod_3_description}
                                </p>
                                <img
                                    className="ow-features__img"
                                    src={u02Img}
                                    alt={text[lang].h2_prod_3_alt}
                                />
                                <div className="ow-features__btn">
                                    <OptBtn
                                        isLink
                                        href="https://optiwe.com/es/productos/chatbot-whatsapp/"
                                        rounded
                                        small
                                        className="ws-button"
                                    >
                                        {text[lang].h2_prod_3_link}
                                    </OptBtn>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="ow-features__title">
                                    {text[lang].h2_prod_4_title}
                                </h3>
                                <p className="ow-features__text">
                                    {text[lang].h2_prod_4_description}
                                </p>
                                <img
                                    className="ow-features__img"
                                    src={u01Img}
                                    alt={text[lang].h2_prod_4_alt}
                                />
                                <div className="ow-features__btn">
                                    <OptBtn
                                        isLink
                                        href="https://optiwe.com/es/productos/marketing-digital-whatsapp/"
                                        rounded
                                        small
                                        className="ws-button"
                                    >
                                        {text[lang].h2_prod_4_link}
                                    </OptBtn>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="ow-features__title">
                                    {text[lang].h2_prod_5_title}
                                </h3>
                                <p className="ow-features__text">
                                    {text[lang].h2_prod_5_description}
                                </p>
                                <img
                                    className="ow-features__img"
                                    src={u06Img}
                                    alt={text[lang].h2_prod_5_alt}
                                />
                                <div className="ow-features__btn">
                                    <OptBtn
                                        isLink
                                        href="https://optiwe.com/es/productos/reportes-whatsapp/"
                                        rounded
                                        small
                                        className="ws-button"
                                    >
                                        {text[lang].h2_prod_5_link}
                                    </OptBtn>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="ow-features__title">
                                    {text[lang].h2_prod_6_title}
                                </h3>
                                <p className="ow-features__text">
                                    {text[lang].h2_prod_6_description}
                                </p>
                                <img
                                    className="ow-features__img"
                                    src={u05Img}
                                    alt={text[lang].h2_prod_6_alt}
                                />
                            </div>
                        </div>

                        <div className="features__cta mt-5 mb-5">
                            {renderSignUpButton('register')}
                        </div>
                    </div>
                </section>

                {/*
                ************
                * APPS *
                ************
                */}
                <section className="container-fluid ow-section ow-section--features" id="features">
                    <div className="container features">
                        <div className="row">
                            <div className="col-md-12 features__content">
                                <h2 className="ow-section__title">
                                    <span className="bold">{text[lang].h2_apps}</span>
                                </h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <a href="https://play.google.com/store/apps/details?id=com.optiwe.agent" target="_blank">
                                    <img
                                        className="ow-apps__img"
                                        src={androidImg}
                                        alt={text[lang].h2_apps_android_alt}
                                    />
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a href="https://apps.apple.com/us/app/agent-optiwe/id6472497867" target="_blank">
                                    <img
                                        className="ow-apps__img"
                                        src={iosImg}
                                        alt={text[lang].h2_apps_ios_alt}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                {/*
                ************
                * CUSTOMERS *
                ************
                */}
                <section className="clients-shapes">
                    <div className="client_top_shape_wrapper">
                        <svg
                            className="svg_top_shape"
                            id="client_top_shape"
                            data-name="client_top_shape"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMidYMid slice"
                            viewBox="0 0 1920 595"
                        >
                            <path
                                className="clients_top_shape"
                                d="M1920,595V0c-89.19,9.65-163.93,79.77-218.34,153.38-59,79.8-107.78,170.9-189.35,227.3-42.59,29.6-92,47.9-142.07,60.9-54.28,14.1-110.67,22.1-166.55,17.3-88.88-7.6-172.16-47.2-260.44-59.5a433,433,0,0,0-255.13,43c-37.89,19.4-72.78,44.3-110.57,63.9C472.28,561,345,571.38,232.24,534.68,151.25,508.33,76.61,458.85,0,420.92V595Z"
                            />
                        </svg>
                    </div>
                    <div
                        className="container-fluid ow-section ow-section--success-stories"
                        id="success-stories"
                    >
                        <div className="container success-stories">
                            <div className="row justify-content-center mb-5">
                                <div className="col-md-10">
                                    <h2 className="ow-section__title">
                                        <span className="bold">{text[lang].h2_cs}</span>
                                    </h2>
                                </div>
                                <div className="row">
                                    <div className="col-md-10"></div>
                                </div>
                            </div>
                            <Stories stories={clientStories} />
                        </div>
                    </div>
                    <svg
                        id="client_bottom_shape"
                        data-name="client_bottom_shape"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1920 170"
                    >
                        <path
                            className="client_bottom_shape"
                            d="M0,0V126.36c143.66,40.06,284.7,51.63,445.48,4.58,236.34-69.2,456.88-50.6,696.62-15.6,149.46,21.9,301.72,27,451.88,8.7,111.21-13.59,217-45.86,326-64.76V0Z"
                        />
                    </svg>
                </section>

                {/*
                ************
                * CHANNELS *
                ************
                */}
                <section className="container-fluid ow-section ow-section--channels" id="channels">
                    <div className="container">
                        <div className="row d-flex align-items">
                            <div className="col-md-6 d-flex align-items-center order-1 order-ms-2">
                                <div className="ow-section__content">
                                    <h2 className="ow-section__title">
                                        <span className="bold">{text[lang].h2_canales}</span>
                                    </h2>
                                    <h3 className="ow-section__subtitle">
                                        {text[lang].h2_canales_h3}
                                    </h3>
                                    <p className="ow-section__text">
                                        {text[lang].h2_canales_description}
                                    </p>
                                    <div className="row ow-section--channels__btn">
                                        {renderSignUpButton('test')}
                                    </div>
                                    <div className="row ow-section--channels__btn">
                                        <OptBtn
                                            isLink
                                            href="https://business.whatsapp.com/products/business-platform"
                                            rounded
                                            small
                                        >
                                            {text[lang].h2_canales_whatsapp_platform}
                                        </OptBtn>
                                    </div>
                                    <div className="row ow-section--channels__btn">
                                        <OptBtn
                                            isLink
                                            href="https://developers.facebook.com/docs/whatsapp/"
                                            rounded
                                            small
                                        >
                                            {text[lang].h2_canales_whatsapp_business_api}
                                        </OptBtn>
                                    </div>
                                    <div className="row ow-section--channels__btn">
                                        <OptBtn
                                            isLink
                                            href="https://openai.com/blog/chatgpt"
                                            rounded
                                            small
                                            className="ws-button"
                                        >
                                            {text[lang].h2_canales_chatgpt}
                                        </OptBtn>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-2 order-md-1">
                                <img src={mobile} alt="Optiwe" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </section>

                {/*
                ****************
                * INTEGRATIONS *
                ****************
                */}
                <section className="integrations-shapes">
                    <svg
                        id="integrations_top_shape"
                        data-name="integrations_top_shape 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1920 88"
                    >
                        <path
                            className="integrations_top_shape"
                            d="M1920.5,88V43.27C1753,54.14,1581.09,22.09,1411.3,8.49a2363.54,2363.54,0,0,0-388.3,1c-107.6,9.2-212.8,28.4-321.2,34.8-102.5,6.1-195.5,5.9-297.8-3.9C276.5,28.13,126.69,6.36.5,30.37V88Z"
                        />
                    </svg>
                    <div
                        className="container-fluid ow-section ow-section--clients"
                        id="integrations"
                    >
                        <div className="container">
                            <h2 className="ow-section--clients__title">
                                <span className="bold">{text[lang].h2_integrations}</span>
                            </h2>
                            <Integrations />
                        </div>
                    </div>
                    <svg
                        id="integrations_bottom_shape"
                        data-name="integrations_bottom_shape"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1920 82.17"
                    >
                        <path
                            className="integrations_bottom_shape"
                            d="M0,0V25.07C100.42,73.33,222.92,85.71,339.3,81.39c156.8-5.8,310.1-35.9,467.5-35.3,150.2.5,300.5,20.5,450.6,13.9,134.5-5.9,267.1-42.9,402.7-27.6,84,9.52,172.75,28.69,259.9,33.2V0Z"
                        />
                    </svg>
                </section>

                <section className="benefits-shapes">
                    <svg
                        id="benefits_top_shape"
                        data-name="benefits_top_shape"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        viewBox="0 0 1920 143.11"
                    >
                        <path
                            className="benefits_top_shape"
                            d="M1920,143.11V38.75C1692.68,104.88,1412.86,127.9,1184.41,89,1083.28,71.8,983,44.3,880.43,45.1,705.88,46.4,536.94,129.6,363.09,114c-77.32-7-151.94-33.2-229.26-39.5C89,70.85,41.09,75.79,0,92.94l.22,50.16Z"
                        />
                        <path
                            className="benefits_top_shape"
                            d="M384,72.49c5.25-.88,10.65-1.89,15.23-4.62s8.23-7.75,7.92-13-4.74-9.86-9.87-11.6-10.8-1-15.93.58c-4,1.19-8.2,3-12.06,1.58-4.14-1.57-6.17-6-8.85-9.53C353,25.85,337.1,22.4,327.17,30c-6,4.54-9.81,15.35-7.06,22.55a25.66,25.66,0,0,0,5.42,8c6.64,6.93,17.09,14.23,27,15.22S374,74.25,384,72.49Z"
                        />
                        <path
                            className="benefits_top_shape"
                            d="M443.55,31.61c3.18-3.33,5.56-8.65,2.74-12.32-2.34-3-7.15-3-10.31-.93-1.72,1.09-4.24,3.37-4.92,5.41-1.2,3.23-1.83,9.65,1.81,11.43C436.71,37.19,440.95,34.38,443.55,31.61Z"
                        />
                    </svg>
                    <div className="container-fluid ow-section ow-section--faq">
                        <div className="container">
                            <div className="table-pricing-title">
                                <h2 className="ow-section__title">
                                    <span className="bold">{text[lang].h2_faq}</span>
                                </h2>
                            </div>
                            <FaqCard
                                _isOpen={true}
                                title={text[lang].faq_1}
                                text={text[lang].faq_1_description}
                            />
                            <FaqCard
                                _isOpen={true}
                                title={text[lang].faq_2}
                                text={text[lang].faq_2_description}
                            />
                            <FaqCard
                                _isOpen={true}
                                title={text[lang].faq_3}
                                text={text[lang].faq_3_description}
                            />
                            <FaqCard
                                _isOpen={true}
                                title={text[lang].faq_4}
                                text={text[lang].faq_4_description}
                            />
                        </div>
                    </div>
                </section>
            </Layout>
        </ErrorBoundaryWithLogger>
    )
}
export default Index
